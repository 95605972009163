import * as React from "react"
import Layout from "../components/layout"
import { BaseImage, DuoImage } from "../components/photos"
import * as styles from "./index.module.scss"
import pic1 from "../images/nl/1.jpg"
import pic2 from "../images/nl/2.jpg"
import pic3 from "../images/nl/3.jpg"
import pic4 from "../images/nl/4.jpg"
import pic5 from "../images/nl/5.jpg"
import pic6 from "../images/nl/6.jpg"
import pic7 from "../images/nl/7.jpg"
import pic8 from "../images/nl/8.jpg"
import pic9 from "../images/nl/9.jpg"
import pic10 from "../images/nl/10.jpg"
import pic11 from "../images/nl/11.jpg"
import pic12 from "../images/nl/12.jpg"
import pic13 from "../images/nl/13.jpg"
import pic14 from "../images/nl/14.jpg"
import pic15 from "../images/nl/15.jpg"
import pic16 from "../images/nl/16.jpg"
import pic17 from "../images/nl/17.jpg"
import pic18 from "../images/nl/18.jpg"
import pic19 from "../images/nl/19.jpg"
import pic20 from "../images/nl/20.jpg"
import pic21 from "../images/nl/21.jpg"
import pic22 from "../images/nl/22.jpg"
import pic23 from "../images/nl/23.jpg"
import pic24 from "../images/nl/24.jpg"
import pic25 from "../images/nl/25.jpg"

class NateLorr extends React.Component {
    render() {
        return (
            <Layout title="Proposal - Nate/Lorr">
                <div className={styles.photoBlog}>
                    <p>
                        <a href="/">
                            Home
                        </a>
                        <h3>Nate and Lorr</h3>
                        <div>Plano, TX - September 2024</div>
                    </p>
                    <BaseImage imgSrc={pic1} />
                    <DuoImage imgSrc={pic2} imgSrc2={pic3} />
                    <DuoImage imgSrc={pic4} imgSrc2={pic5} />
                    <BaseImage imgSrc={pic6} /> 
                    <BaseImage imgSrc={pic7} />
                    <BaseImage imgSrc={pic8} />
                    <BaseImage imgSrc={pic9} />
                    <BaseImage imgSrc={pic10} />
                    <BaseImage imgSrc={pic11} />
                    <BaseImage imgSrc={pic12} />
                    <BaseImage imgSrc={pic13} />
                    <DuoImage imgSrc={pic14} imgSrc2={pic15} />
                    <BaseImage imgSrc={pic16} />
                    <BaseImage imgSrc={pic17} />
                    <DuoImage imgSrc={pic18} imgSrc2={pic19} />
                    <BaseImage imgSrc={pic20} />
                    <BaseImage imgSrc={pic21} />
                    <DuoImage imgSrc={pic22} imgSrc2={pic23} />
                    <BaseImage imgSrc={pic24} />
                    <BaseImage imgSrc={pic25} />
                </div>
            </Layout>
        )
    }
}

export default NateLorr
